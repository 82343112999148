import showMore from "./modules/showMore";
import anchors from "./modules/anchor";
import nav from "./modules/nav";
import form from "./modules/form";


document.addEventListener('DOMContentLoaded', ()=> {
    showMore();
    anchors();
    nav();
    form();
});