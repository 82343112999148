function nav () {
    const nav = document.querySelector('.nav');
    const burger = document.querySelectorAll('.header__burger');
    const links = document.querySelectorAll('.nav__link');

    burger.forEach(el=> {
        el.addEventListener('click', ()=> {
            el.classList.toggle('active');
            nav.classList.toggle('show');
        });
    });

    links.forEach(el=> {
        el.addEventListener('click', ()=> {
            burger.forEach(el=> el.classList.toggle('active'));
            nav.classList.remove('show');
        });
    });
}

export default nav;