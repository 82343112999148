function form () {
    const form = document.querySelector('.feedback__form');

    if(form) {
        /*=======
        Настраиваем проверку инпутов и ajax для формы обратной связи
        =======*/
        //Находим инпуты в форме
        const inputName = document.querySelector('#name');
        const inputPhone = document.querySelector('#phone');

        //Проверяем инпуты в процессе заполнения на кол-во символов
        function checkLength () {
            const nameVal = inputName.value.length;
            const phoneVal = inputPhone.value.length;

            //Если инпут имя и телефон соответствую условиям, удаляем класс ошибки
            if (nameVal >= 2) inputName.classList.remove('error');
            if (phoneVal > 9) inputPhone.classList.remove('error');
        }

        // Используем регулярное выражение для удаления всех символов, кроме цифр и "+" в телефоне
        function checkNum () {
            let cleanedValue = inputPhone.value.replace(/[^0-9+]/g, '');
            // Обновляем значение в поле ввода
            inputPhone.value = cleanedValue;
        }

        //Вызываем функцию проверки
        inputName.addEventListener('input', checkLength);
        inputPhone.addEventListener('input', function() {
            checkLength();
            checkNum();
        });

        //Находим форму и значение атрибута action для ajax
        const action = form.getAttribute('action');

        form.addEventListener('submit', (e) => {
            //Отключаем стандартное поведение формы
            e.preventDefault();

            const nameVal = inputName.value.length;
            const phoneVal = inputPhone.value.length;

            //Проверяем не пустые ли инпуты и если да, то добавляем класс ошибки
            if (nameVal < 2) inputName.classList.add('error');
            if (phoneVal <= 9) inputPhone.classList.add('error');

            
            if(nameVal >= 2 && phoneVal > 9) {
                //Если инпуты соответствуют проверке удаляем класс ошибки с инпутов при отправке
                inputName.classList.remove('error');
                inputPhone.classList.remove('error');

                //И отправляем запрос на сервер
                const formData = new FormData(form);
                const request = new XMLHttpRequest();

                request.open('POST', action);
                request.send(formData);

                request.addEventListener('load', () => {
                    if (request.status === 200) {
                        const successModal = document.querySelector('.feedback__form-success');

                        form.reset();

                        setTimeout(()=>{
                            successModal.classList.add('show');
                        }, 300);
                        
                        setTimeout(()=> {
                            successModal.classList.remove('show');
                        }, 6000);
                    } else {
                        alert('Что-то пошло не так, попробуйте снова или проверьте подключение к интернету');
                    }
                });
            }
        });
    }
}

export default form;